@import "./../colors";

$color-validation-error: $block-red;
$color-validation-success: #22b14c;
$color-toggle-password-icon: $darkgrey;
$color-generic-message-border: $block-red;
$color-generic-message-background: #ffd8d8;

.block.newsletter {
    max-width: 50em;
    margin: 40px auto 56px !important;

    // Hide block open/close icons
    .icon.down,
    .icon.open,
    .icon.up {
        display: none !important;
    }

    @media only screen and (max-width: 500px) {
        width: 98%;
        margin: 20px auto;
    }

    .block-content {
        padding: 8px 12px 16px;
    }

    .form-group {
        input[type="checkbox"] {
            height: 1em;
        }

        &.introduction {
            margin-bottom: .25em;
        }

        &.generic-message {
            margin-bottom: 0;
        }

        &.name {
            .first-name {
                flex: 3;
                margin-right: 1em;
            }

            .middle-name {
                flex: 2;
                margin-right: 1em;
            }

            .last-name {
                flex: 4;
            }

            @media only screen and (max-width: 500px) {
                flex-wrap: wrap;

                .group-element {
                    flex: 0 0 100%;
                }
            }
        }

        &.gender {
            .input-element + .input-element {
                margin-left: 1em;
            }

            input[type="radio"] {
                margin-right: .5em;
            }

            @media only screen and (max-width: 500px) {
                > * {
                    flex: 1;
                }
            }

            .input-element {
                display: flex;
                align-items: center;
            }

            .error-message {
                width: 100%;
                margin-bottom: .75em;
            }
        }

        &.newsletters {
            margin-top: 1em;

            .group-element + .group-element {
                margin-top: .75em;
            }
        }

        &.terms-and-conditions {
            margin-top: 1em;
        }

        &.navigation {
            button {
                width: 100%;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }

    .confirmation-container {
        .link-show-homepage {
            margin-bottom: .25em;
        }
    }
}
