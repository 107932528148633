@import "colors";

$color-validation-error: $block-red;
$color-validation-success: color("green-400");

.form-container {
    form {
        .input-group {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1em;

            .icon-success {
                color: $color-validation-success;
            }

            .icon-error {
                color: $color-validation-error;
            }

            svg {
                position: absolute;
                top: .2em;
                right: .2em;
                width: 1.75em;
                height: 1.75em;
                user-select: none;
            }

            input:not([type="checkbox"], [type="radio"]) {
                width: inherit;
                min-width: 20px;
                padding-left: 5px;
            }

            svg:not(.hidden) ~ input:not([type="checkbox"]) {
                padding-right: 3em;
            }

            &.validation--success {
                border-color: currentcolor;
            }
        }

        .error-message {
            padding-top: .25em;
            color: $color-validation-error;
        }

        .form-group {
            display: flex;
            flex-direction: row;

            &.first-group {
                margin-top: .75em;
            }

            &.generic-message {
                .validation--error,
                .validation--success {
                    border: none;
                }
            }

            .group-element {
                .margin-right {
                    margin-right: 1em;
                }
            }

            &.generic-message:not(.hidden) + .form-group.navigation {
                margin-top: .5em;
            }

            &.navigation {
                display: flex;
                flex-direction: column;
                margin-top: 1.5em;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    padding: 9px;
                    border: none;
                    border-radius: 2px;
                    background-color: color("orange");
                    color: $white;
                    font-size: 13px;
                    text-decoration: none;
                    cursor: pointer;
                }

                @media only screen and (max-width: 500px) {
                    button {
                        width: 100%;
                    }
                }

                .icon-spinner {
                    width: 1.25em;
                    margin-right: .75em;
                }
            }

            &.g-recaptcha {
                height: 0;
                margin: 0;
            }

            .checkbox-wrapper {
                display: flex;
                cursor: pointer;

                > * {
                    cursor: pointer;
                }

                label {
                    margin-left: .5em;
                    line-height: 1;
                }

                @media only screen and (max-width: 500px) {
                    align-items: start;
                }
            }
        }
    }
}

.form-container,
.confirmation-container {
    a {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
    }

    //  Utility classes
    .hidden,
    &.hidden {
        display: none !important;
    }

    .flex {
        display: flex;
    }

    .flex-row {
        flex-direction: row;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .items-center {
        align-items: center;
    }

    .items-end {
        align-items: flex-end;
    }

    .justify-center {
        justify-content: center;
    }

    .bold {
        font-weight: bold;
    }
}

.confirmation-container {
    display: flex;
    flex-direction: column;

    .navigation {
        height: 2.25em;
    }
}
